export default theme => {
  const styles = {
    root: {
      border: 'none',
      height: 'fit-content',
      [theme.breakpoints.up(500)]: {
        height: 40,
      },
    },

    flex: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    },

    wrap: {
      [theme.breakpoints.down(500)]: {
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        gap: 10,
      },
    },
  };
  return styles;
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Paper from 'components/Paper';
import styles from './styles';

class FilterBar extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    className: PropTypes.string,
    disableFlex: PropTypes.bool,
    children: PropTypes.node,
  };

  static defaultProps = {
    disableFlex: false,
    children: null,
    className: '',
  };

  render() {
    const { classes, className, disableFlex, children } = this.props;
    return (
      <Paper flex className={cn(classes.root, { [classes.flex]: !disableFlex }, className)} contentClass={classes.wrap}>
        {children}
      </Paper>
    );
  }
}

export default withStyles(styles)(FilterBar);

export default theme => ({
  paper: {
    maxWidth: 330,
    borderRadius: '4px !important',
    width: 'auto',
    height: 'inherit',
  },

  mobilePaper: {
    left: '0 !important',
    maxHeight: '100%',
    borderRadius: '20px 20px 0 0',
    maxWidth: 500,
    width: '100vw',
    paddingBottom: 90,
    paddingTop: 0,
    [theme.breakpoints.up(500)]: {
      maxWidth: 450,
      right: '0 !important',
      left: 'unset !important',
    },
    [theme.breakpoints.up('postmd')]: {
      borderRadius: 0,
    },
  },

  paywall: {
    width: 200,
    height: 200,
    padding: '20px 25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paywallContainer: {},

  paywallLabel: {
    color: theme.palette.text.primary,
    lineHeight: theme.typography.pxToRem(16),
    textAlign: 'center',
    marginBottom: 20,
  },

  paywallIcon: {
    marginBottom: 17,
  },

  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  buttonActive: {
    color: theme.palette.text.link,
  },

  buttonTag: {
    color: theme.palette.text.primary,
    padding: 0,
    minWidth: 0,
    fontWeight: 'normal',
  },

  button: {
    border: `1px solid ${theme.palette.border}`,
  },

  lockIcon: {
    fontSize: theme.typography.pxToRem(13),
    marginRight: 7,
  },

  opened: {
    border: `1px solid ${theme.palette.text.link} !important`,
  },

  backdrop: {
    zIndex: 1250,
    height: '100vh',
    width: '200vw',
  },
});
